<!--
 * @Author       : JiangChao
 * @Date         : 2024-04-24 09:09:28
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-11-13 21:03:44
 * @Description  : 修改备注
-->
<template>
  <el-dialog
    :visible.sync="dialogFormVisible"
    :append-to-body="true"
    :modal="false"
  >
    <template slot="title">
      <div class="font-heavy" style="font-size: 18px;color: #303133">
        {{ $t("modify_remarks") }}
      </div>
    </template>
    <div class="container">
      <el-form
        :model="addForm"
        status-icon
        ref="resetRef"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label-width="0px">
          <el-input
            clearable
            v-model="addForm.remark"
            autocomplete="off"
            :placeholder="$t('please_enter')"
            type="textarea"
          >
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("cancel")
      }}</el-button>
      <el-button type="primary" @click="handelOk">{{
        $t("confirm")
      }}</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { editShipmentRemarkApi } from "@/api/api.js";

export default {
  name: "ChangeShipmentRemarkDialog",
  data() {
    return {
      addForm: {
        remark: "",
      },
      dialogFormVisible: false,
      timezone: 0,
    };
  },
  methods: {
    showDialog(id) {
      this.dialogFormVisible = true;
      this.shipmentId = id;
      this.addForm.remark = "";
    },
    handelOk() {
      let params = {
        ...this.addForm,
        shipmentId: this.shipmentId,
        timezone: this.timezone,
      };
      editShipmentRemarkApi(params)
        .then((r) => {
          console.log("=====", r);

          this.$message.success(this.$t("modify_success"));

          this.dialogFormVisible = false;
          this.$emit("refresh");
        })
    },
  },
  props: {},
  components: {},
  computed: {
    selectedTimeZone() {
      return this.$store.getters.selectedTimeZone;
    },
    setSelectedLanguage() {
      return this.$store.getters.selectedLanguage;
    },
  },
  watch: {
    selectedTimeZone(newVal) {
      console.log("newVal: ", newVal);
      this.timezone = newVal;
    },
    setSelectedLanguage(newVal) {
      this.$i18n.locale = newVal;
    },
  },
  mounted() {
    this.$i18n.locale = this.setSelectedLanguage;
    this.timezone=this.selectedTimeZone

  },
  created() {},
};
</script>

<style scoped lang="less"></style>
